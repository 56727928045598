/*
 * Global CP styles
 */
 .white-bg-tint {
 	background: var(--neutral-white);
 	background: rgba(255,255,255,.97);
 }
 .cp-border-radius {
 	overflow: hidden;
 }
 @media (min-width: 798px) {
 	.cp-border-radius {
 		-webkit-border-radius: 10px;
 		-moz-border-radius: 10px;
 		border-radius: 10px;
 	}
 }
 .cp-nav {
	line-height: 0;
 }
 .cp-icon {
 	background: url(/images/global/personalizer/sprite_cp_icons.svg) no-repeat;
 }
 .no-svg .cp-icon {
 	background-image: url(/images/global/personalizer/sprite_cp_icons.png);
 }
 .cp-nav-links .cp-icon.spinner {
 	background: url(/images/global/ajax_loader.gif) no-repeat transparent;
 	background-position: center center;
 	background-size: contain;
 	display: none;
 }
 .cp-shadow {
 	-webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,.75);
 	-moz-box-shadow: 0 4px 5px 0 rgba(0,0,0,.75);
 	box-shadow: 0 4px 5px 0 rgba(0,0,0,.75);
 }
 @media (max-width: 767px) {
 	.cp-shadow {
 		-webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.4);
 		-moz-box-shadow: 0 2px 2px 0 rgba(0,0,0,.4);
 		box-shadow: 0 2px 2px 0 rgba(0,0,0,.4);
 	}
 }

/*
 * CP Header & Nav
 */

.welcome .pc-icons {
	margin: 0;
	vertical-align: baseline;
}
.web-font .no-letter-spacing{
	letter-spacing: normal;
}
.cp-header, .cp-footer > .inner-wrapper {
	margin: 0 auto;
	max-width: 100%;
	width: auto;
}
.cp-footer {
	background: var(--neutral-white);
  /* margin-top: 30px; */
	padding: 25px 0 10px;
	border-top: 24px solid var(--auxiliary-blue-light);
}
.js .cp-booking, .js .cp-nav {
	display: none;
	position: relative;
}
.cp-booking .booking-number {
	display: none;
}
.gifting-nav{
	padding: 0px;
}

.cp-nav-btn > a:hover,.cp-nav-btn > a:focus, .summary-pg .summary-btn, .checkin-pg .checkin-btn, .pif-pg .checkin-btn, .medical-dietary-pg .checkin-btn, .payment-pg .payment-btn, .itinerary-pg .itinerary-btn, 
.flights-pg .flights-trans-btn, .flights-pg .flights-btn, .excursion-pg .excursion-btn, .onboardres-pg .onboardres-btn, .insurance-pg .insurance-btn, .insurance-pg .waiver-btn, .gifting-onboardres-pg .onboardres-btn, .gifting-checkout-pg .checkout-btn, .specialtydining-pg .specialty-dining-btn {
	background: var(--auxiliary-blue-light);
}
.summary-pg .summary-btn, .checkin-pg .checkin-btn, .payment-pg .payment-btn, .itinerary-pg .itinerary-btn, 
.flights-pg .flights-btn, .excursion-pg .excursion-btn, .onboardres-pg .onboardres-btn, .insurance-pg .insurance-btn, .specialtydining-pg .specialty-dining-btn {
	cursor: default;
}
#flightsTransBtn + #flightsBtn {
	display: none;
}
.web-font .cp-header {
	font-family: var(--red-hat-text);
	letter-spacing: 1px;
}
.cp-header h1 {
	margin: 0;
}
@media screen and (min-width: 768px) {
	.cp-header h1 {
		margin-top: 15px;
	}
}
.cp-header h1 + p {
	margin-top: 0 ;
}
.cp-nav {
	font-size: 1.153846153846154em;
}
.web-font .cp-nav {
	font-family: var(--red-hat-text);
	letter-spacing: 1px;
}
.web-font .cp-nav > ul {
	letter-spacing: 0;
}
.cp-nav-links > li, .cp-header-links > li  {
	font-size: 0.971em;
	/*float: left;*/
}

.web-font .cp-nav-btn {
	letter-spacing: 1px;
}
.cp-nav-btn > a {
	background: var(--neutral-white);
	display: block;
	text-decoration: none;
}
@media screen and (min-width: 500px) {
	.cp-header-links .cp-nav-btn {
		float: left;
		width: 50%;
	}
}
.count-down,
.shop-pax {
  display: block;
  text-align: center;
}
.count-down {
  color: var(--neutral-white);
  font-size: 1em;
}
.shop-pax {
  font-size: 1.384615384615385em;
}
@media screen and (min-width: 768px) {
  .count-down,
  .shop-pax {
    display: inline;
    text-align: left;
  }
  .count-down {
    color: var(--neutral-black);
  }
  /* .cp-header-links > li {
    font-size: 1.15em;
  } */
}
.cp-header-links {
  list-style-type: none;
  margin: 0 0 15px 0;
  padding: 0;
}
.go-to-pc-btn > a {
	background: rgba(0,84,160,.8);
	color: var(--neutral-white);
	font-weight: 400;
}
.go-to-pc-btn > a:hover {
	background: rgba(0,84,160,1);
}
.no-rgba .go-to-pc-btn {
	background: var(--primary-blue);
}
.no-rgba .go-to-pc-btn:hover {
	background: var(--primary-blue);
}
.cp-touch-menu {
	background: var(--neutral-white);
	border: none;
	line-height: 1;
	margin: 0;
	padding: 0 60px;
	position: fixed;
	text-align: center;
	top: 0;
	width: 100%;
	z-index: 1000;
}
/*.cp-touch-menu:focus {
	outline: 0; 
}*/
@media (max-width: 767px) and (orientation: landscape) {
	.cp-touch-menu {
		position: absolute;
	}
}
.my-booking-link {
	display: inline-block;
	padding: 15px;
	text-decoration: none;
}
.princess-sea-witch > img {
	bottom: 2px;
	left: 0;
	position: absolute;
	width: 100%;
}
#cp-touch-phone {
	left: 0;
}
#cp-touch-menu {
	padding-top: 12px;
	right: 0;
}
/* all rules with #cp-header are being used to over right legacy rwd header_footer styles - delete once new rwd is live */
#cp-header .touch-icon {
	background: transparent;
	border: none;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	height: 100%;
	line-height: 1;
	margin: 0;
	padding: 8px 5px 20px;
	position: absolute;
	text-align: center;
	top: 0;
	width: 50px;
}
#cp-header .touch-bar {
	background: var(--primary-blue);
}
.cp-touch-menu .touch-icon:focus, .isActive .cp-touch-menu .touch-icon:focus {
	background: var(--neutral-white);
	outline: 0;
}
.cp-touch-menu .touch-icon:hover, .my-booking-link:hover, 
#cp-header .cp-touch-menu .touch-icon:hover, #cp-header .my-booking-link:hover {
	background: var(--auxiliary-blue-light);
}
.isActive #cp-touch-menu {
	background: var(--auxiliary-blue-dark); /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIxJSIgc3RvcC1jb2xvcj0iIzIwNDI2NiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0MTVjN2MiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  var(--auxiliary-blue-dark) 1%, var(--primary-blue-dark) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(1%,var(--auxiliary-blue-dark)), color-stop(100%,var(--primary-blue-dark))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  var(--auxiliary-blue-dark) 1%,var(--primary-blue-dark) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  var(--auxiliary-blue-dark) 1%,var(--primary-blue-dark) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  var(--auxiliary-blue-dark) 1%,var(--primary-blue-dark) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  var(--auxiliary-blue-dark) 1%,var(--primary-blue-dark) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--auxiliary-blue-dark)', endColorstr='var(--primary-blue-dark)',GradientType=0 ); /* IE6-8 */
}
.isActive #cp-touch-menu:hover, #cp-header.isActive #cp-touch-menu:hover {
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzIwNDI2NiIgc3RvcC1vcGFjaXR5PSIwLjgiLz4KICAgIDxzdG9wIG9mZnNldD0iMSUiIHN0b3AtY29sb3I9IiMyMDQyNjYiIHN0b3Atb3BhY2l0eT0iMC44Ii8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0MTVjN2MiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top,  rgba(32,66,102,0.8) 0%, rgba(32,66,102,0.8) 1%, rgba(65,92,124,1) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(32,66,102,0.8)), color-stop(1%,rgba(32,66,102,0.8)), color-stop(100%,rgba(65,92,124,1))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(32,66,102,0.8) 0%,rgba(32,66,102,0.8) 1%,rgba(65,92,124,1) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(32,66,102,0.8) 0%,rgba(32,66,102,0.8) 1%,rgba(65,92,124,1) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(32,66,102,0.8) 0%,rgba(32,66,102,0.8) 1%,rgba(65,92,124,1) 100%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(32,66,102,0.8) 0%,rgba(32,66,102,0.8) 1%,rgba(65,92,124,1) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc204266', endColorstr='var(--primary-blue-dark)',GradientType=0 ); /* IE6-8 */
}
.touch-icon .cp-icon {
	display: block;
	height: 20px;
	width: 20px;
	margin: 0 auto;
}
.touch-icon .cp-icon.phone {
	background-position: -592px -127px;
}
.isActive #cp-touch-menu .touch-text {
	color: var(--neutral-white);
}
/* color spec for touch-bar is in global styles */
.isActive .touch-bar, #cp-header.isActive .touch-bar {
	background: var(--neutral-white);
}
.cp-touch-menu {
	display: none;
	visibility: hidden;
}
.isActive .cp-touch-menu {
	-webkit-box-shadow: 0 2px 2px 0 rgba(65,92,124,.5);
	box-shadow: 0 2px 2px 0 rgba(65,92,124,.5);
}
#shipName, #departureDate {
	white-space: nowrap;
}
.cp-nav-btn > .deactive {
	filter: alpha(opacity=100);
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1;
}
.cp-nav-links .deactive > .cp-icon {
	display: none;
}
.cp-icon .cp-icon-text {
	display: none;
}
@media screen and (max-width: 499px) {
	.cp-nav-links .cp-nav-btn + .cp-nav-btn, .cp-header-links .cp-nav-btn + .cp-nav-btn {
		border-top: 1px solid rgba(255,255,255,.5);
	}
}
@media screen and (min-width: 500px) and (max-width: 699px) {
	.cp-nav-links .cp-nav-btn, .cp-header-links .cp-nav-btn {
		border-top: 1px solid rgba(255,255,255,.5);
	}
	.cp-header-links .cp-nav-btn:nth-of-type(1), .cp-header-links .cp-nav-btn:nth-of-type(2) {
		border-top: none;
	}
}
@media screen and (min-width: 700px) and (max-width: 767px) {
	.cp-header-links .cp-nav-btn {
		width: 25%;
	}
	.cp-header-links .cp-nav-btn + .cp-nav-btn {
		border-left: 1px solid rgba(255,255,255,.5);
	}
}
@media screen and (min-width: 500px) and (max-width: 767px) {
	.cp-header-links .cp-nav-btn:nth-of-type(even) {
		border-left: 1px solid rgba(255,255,255,.5);
	}
}
@media screen and (min-width: 640px) and (max-width: 767px) {
	.cp-nav-btn {
		display: inline-block;
		float: left;
	}
	.cp-nav-links > .cp-nav-btn {
		width: 50%;
	}
	.cp-nav-links .cp-nav-btn {
		border-top: 1px solid rgba(255,255,255,.5);
	}
	.cp-nav-links .cp-nav-btn:nth-of-type(1), .cp-nav-links .cp-nav-btn:nth-of-type(2) {
		border-top: none;
	}
	.cp-nav-links > .cp-nav-btn:nth-of-type(even) {
		border-left: 1px solid rgba(255,255,255,.5);
	}
	.cp-header-links .cp-nav-btn > a {
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
	}
	.cp-header-links.hide-faq.hide-associated-booking > .cp-nav-btn {
		width: 100%;
	}
	.cp-header-links.hide-faq > .cp-nav-btn, .cp-header-links.hide-associated-booking > .cp-nav-btn {
		width: 50%;
	}
	.cp-header-links .go-to-pc-btn  + .go-to-pc-btn {
		border-left-color: var(--neutral-white);
	}
}
@media screen and (max-width: 767px) {
	.cp-header {
		padding-top: 50px;
	}
	/* turning off princess-header for mobile view in cp */
	#princess-header {
		display: none;
	}
	.login-pg #princess-header, .error-session-pg #princess-header {
		display: block;
	}
	#itineraryName {
		display: block;
		font-size: 1.2em;
		margin-top: 15px;
	}
	.cp-booking .dash {
		display: none;
	}
	.cp-booking {
		background: transparent;
	}
	.cp-booking-info {
		line-height: 1.25;
		text-align: center;
	}
	.cp-booking-info {
		color: var(--neutral-white);
	}
	.cp-booking-info .sets-sail {
		display: none;
	}
	.cp-nav {
		background: transparent;
		padding: 15px 15px 0 15px;
	}
	.cp-nav-btn {
		line-height: 1;
		position: relative;
	}
	.cp-nav-btn > a {
		padding: 7px 7px 7px 7px;/*10px 10px 10px 7px*/
	}
	.cp-header-links > .cp-nav-btn > a {
		padding: 10px 15px;
	}
	.cp-nav-links .cp-icon {
		display: inline-block;
		margin-right: 3px;
		height: 26px;
		width: 40px;
		vertical-align: middle;
	}
	.cp-nav-links .deactive > .cp-icon.spinner {
		display: inline-block;
	}
	.cp-icon.home {
		background-position: -30px -12px;
	}
	.cp-icon.complete, .cp-icon.Complete {
		background-position: -129px -12px;
	}
	.cp-icon.incomplete, .cp-icon.Incomplete {
		background-position: -1515px -12px;
		text-indent: 0px;
		font-size: 0.5em;
		padding-top: 9px;
		color: var(--auxiliary-red);
		text-decoration: none;
	}
	.cp-icon.ocean-ready.Incomplete {
		background-position: -1674px -12px;
	}
	.cp-icon.ocean-ready.Complete {
		background-position: -1595px -12px;
	}
	.cp-icon.payment-credit {
		background-position: -331px -12px;
	}
	.cp-icon.itinerary {
		background-position: -430px -12px;
	}
	.cp-icon.flights {
		background-position: -530px -12px;
	}
	.cp-icon.shorex {
		background-position: -630px -12px;
	}
	.cp-icon.shorex-light {
		background-position: -1020px -12px;
	}
	.cp-icon.onboard {
		background-position: -731px -12px; /* Ship background */
	}
	.cp-icon.dining {
		background-position: -1415px -12px;
	}
	.cp-icon.insurance {
		background-position: -830px -12px;
	}
	.cp-icon.gifting-lookup {
		background-position: -1330px -10px;
	}
	.cp-icon.gifting-lookup-new {
		background-position: -1240px -10px;
	}
	.cp-icon.gifting-checkout {
		background-position: -331px -12px;
	}
	.cp-icon.drawer-control {
		background-position: -633px -130px;
		height: 18px;
		width: 64px;
	}
	.selected .cp-icon.drawer-control {
		background-position: -710px -130px;
	}
	.cp-icon.down-chevron-white {
		background-position: -784px -126px;
		height: 6px;
		width: 10px;
	}
	.cp-icon.up-chevron-white {
		background-position: -804px -126px;
		height: 6px;
		width: 10px;
	}
	.cp-icon.down-chevron-blue {
		background-position: -784px -141px;
		height: 6px;
		width: 10px;
	}
	.cp-icon.up-chevron-blue {
		background-position: -804px -141px;
		height: 6px;
		width: 10px;
	}
	.text-line {
		display: inline;
		vertical-align: middle;
	}
	.cp-nav-btn .flag-pole {
		display: inline-block;
		margin-left: 7px;
	}
	.cp-nav-btn .flag {
		background: var(--auxiliary-red);
		color: var(--neutral-white);
		font-size: .625em;
		letter-spacing: .1em;
		padding: 2px 4px;
		position: relative;
		text-transform: uppercase;
		z-index: 15;	
	}
	.cp-touch-menu {
		display: inline-block;
		visibility: visible;
	}
	.cp-header > .inner-wrapper {
		/*height: 0;
		opacity: 0;
		overflow: hidden;*/
		position: relative;
		transition: opacity .2s linear 0s;
		background: none;
	}
	.cp-header > .inner-wrapper .cp-nav{
		height: 0;
		opacity: 0;
		overflow: hidden;
		padding: 0px;
	}
	.cp-header > .inner-wrapper .cp-booking #departure-links{
		height: 0;
		opacity: 0;
		overflow: hidden;
	}
	.cp-header.isActive > .inner-wrapper .cp-nav{
		height: auto;
		opacity: 1;
		overflow: auto;
		padding: 15px 15px 0 15px
	}
	.cp-header.isActive > .inner-wrapper .cp-booking #departure-links{
		height: auto;
		opacity: 1;
		font-size: 1.153846153846154em;
	}
	.cp-header.isActive > .inner-wrapper {
		-webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.4);
		box-shadow: 0 2px 2px 0 rgba(0,0,0,.4);
		background: rgb(66,92,124); /* Old browsers */
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/var(--neutral-cool-dark)+0,6c8299+25 */
		background: var(--neutral-cool-dark); /* Old browsers */
		background: -moz-linear-gradient(top,  var(--neutral-cool-dark) 0%, var(--neutral-cool-medium) 25%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  var(--neutral-cool-dark) 0%,var(--neutral-cool-medium) 25%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  var(--neutral-cool-dark) 0%,var(--neutral-cool-medium) 25%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--neutral-cool-dark)', endColorstr='var(--neutral-cool-medium)',GradientType=0 ); /* IE6-9 */
		height: auto;
		opacity: 1;
		display: block;
	}
	.cp-header.isActive .cp-booking:before{
		background: none;
	}
	.cp-nav-wrapper{
		background: none;
	}
}

@media screen and (min-width: 768px) {
	.gifting-nav{
		padding: 0px;
		background: var(--neutral-black);
	}
	.gifting-nav:before {
		content: '';
		display: block;
		height: auto;
		position: absolute;
		width: 100%;
	}
	.cp-header {
		/*margin-bottom: 30px;
		min-height: 235px;
		margin-top: -1px;*/
	}
	.gifting-onboardres-pg .cp-header{
		margin-bottom: 30px;
		min-height: initial;
	}
	.cp-header .inner-wrapper{
		position: relative;
	}
	.cp-booking {
    background: var(--neutral-cool-light);
    border-bottom: var(--neutral-cool-medium) solid 1px;
    /* position: absolute !important;
		top: 93px;
		right: 0px;
		left: 0px;
		z-index:10; */
	}
	#departure-links{
    padding: 0.75em 0em;
	}
	@-moz-document url-prefix() {
			#departure-links{
				padding: 1.14em 0em;
			}
	}
	.no-menu .cp-booking{
		top: 0px;
	}

	.cp-header.no-menu {
		min-height: 0;
	}
	.no-menu .cp-booking {
		padding-bottom: 0;
	}
	.cp-header-links {
		display: inline;
		float: right;
		margin: 0;
	padding: 0;
	}
	.cp-header-links .cp-nav-btn {
		background: none;
		display: inline;
		text-align: right;
		width: auto;
	}
	.cp-header-links .cp-nav-btn + .cp-nav-btn {
		margin-left: 1em;
	}
	.cp-header-links .cp-nav-btn a {
		background: none;
    /* color: var(--neutral-white); */
		padding: 0;
	}
	.cp-header-links .cp-nav-btn a:hover {
		text-decoration: underline;
	}
	.gifting-nav .cp-header-links .cp-nav-btn.go-to-pc-btn {
		display: inline-block;
	}
	.gifting-nav .cp-header-links .cp-nav-btn.go-to-pc-btn a{
		padding: 0px !important;
	}
	.cp-header-links .cp-nav-btn.go-to-pc-btn {
		display: none;
	}
	.cp-nav {
		text-align: center;
	}
	.cp-nav > ul {
		display: inline-block;
	}
	.cp-nav-btn {
		display: inline-block;
		font-size: 1em;
		position: relative;
		float: left;
	}
	.cp-nav-btn > a {
		padding: 10px 10px; /*22px*/
		text-align: center;
		text-decoration: none;
	}
	.gifting-onboardres-pg .cp-nav-btn > a, .gifting-checkout-pg .cp-nav-btn > a {
		padding: 15px 25px !important;
	}
	.cp-nav-btn > a:hover > .text-line {
		text-decoration: underline;
	}
	.cp-nav-btn .text-line {
		display: block;
		line-height:1.28em;
	}
	.flights-btn .text-line {
		min-width: 60px;
	}
	.cp-nav-links .cp-icon {
		height: 45px;
		margin: 0 auto .5em;
		width: 68px;
	}
	.cp-nav-links .deactive > .cp-icon.spinner {
		display: block;
		background-size: auto auto;
	}
	.cp-icon.home {
		background-position: -16px -52px;
	}
	.cp-icon.complete, .cp-icon.Complete {
		background-position: -115px -52px;
	}
	.cp-icon.incomplete, .cp-icon.Incomplete {
		background-position: -1495px -52px;
		min-width:72px;
		text-indent: 0px;
		padding-top: 15px;
		color: var(--auxiliary-red);
		font-size: 0.9em;
		font-weight: 600;
	}
	.cp-icon.ocean-ready {
		background-position: -1580px -52px;
	}
	.cp-icon.ocean-ready.Incomplete {
		background-position: -1658px -52px;
	}
	.cp-icon.payment-credit {
		background-position: -317px -51px;
	}
	.cp-icon.itinerary {
		background-position: -416px -52px;
	}
	.cp-icon.flights {
		background-position: -516px -54px;
		width: 68px;
	}
	#flightsBtn .cp-icon.flights {
		background-position: -518px -54px;
	}
	.cp-icon.shorex {
		background-position: -616px -53px;
		width: 68px;
	}
	.cp-icon.onboard {
		/* background-position: -917px -50px; Lotus background */
		background-position: -716px -50px; /* Ship background */
		width: 68px;
	}
	.cp-icon.dining {
		background-position: -1400px -52px;
	}
	.cp-icon.insurance {
		background-position: -816px -52px;
	}
	.cp-icon.gifting-lookup {
		background-position: -1286px -52px;
		min-width: 100px;
	}
	.cp-icon.gifting-lookup-new {
		background-position: -1210px -52px;
		min-width: 100px;
	}
	.cp-icon.gifting-checkout {
		background-position: -317px -51px;
		margin-left: 1em;
	}
	.cp-icon.drawer-control {
		background-position: -633px -130px;
		height: 18px;
		width: 64px;
	}
	.cp-icon.down-chevron-white {
		background-position: -784px -126px;
		height: 6px;
		width: 10px;
	}
	.cp-icon.up-chevron-white {
		background-position: -804px -126px;
		height: 6px;
		width: 10px;
	}
	.cp-icon.down-chevron-blue {
		background-position: -784px -141px;
		height: 6px;
		width: 10px;
	}
	.cp-icon.up-chevron-blue {
		background-position: -804px -141px;
		height: 6px;
		width: 10px;
	}
	.selected .cp-icon.drawer-control {
		background-position: -710px -130px;
	}
	.cp-nav-btn .flag-pole {
		bottom: -12px;
		left: 0;
		line-height: 20px;
		position: absolute;
		right: 0;
		z-index: 999;
	}
	.cp-nav-btn .flag {
		background: var(--auxiliary-red);
		color: var(--neutral-white);
		font-size: 0.9em;
		letter-spacing: .1em;
		padding: 2px 4px;
		position: relative;
		text-transform: uppercase;
		z-index: 15;
	}
	.cp-nav-btn .flag:before {
		border: 9px solid transparent;	
		border-left-width: 7px;
		border-right-width: 0;
		border-bottom-color: var(--auxiliary-red);
		content: ' ';
		font-size: 0;
		height: 0;
		left: -7px;
		line-height: 0;
		position: absolute;
		width: 0;
		top: -9px;
	}
	.cp-icon.spinner {
		background-size: auto;
	}
	#paxCheckinBtn .cp-icon.spinner {
		min-width: 72px;
	}
	#flightsTransBtn .cp-icon.spinner {
		min-width: 68px;
	}
	#flightsBtn .cp-icon.spinner {
		min-width: 68px;
	}
	.cp-header-btns {
		text-align: right;
	}
	.cp-header-btns a + a {
		margin-left: 2em;
	}
	.cp-icon .cp-icon-text {
		display: inline-block;
	}
}
@media screen and (min-width: 798px) and (max-width: 1019px)  {
	.cp-footer > .inner-wrapper {
		margin-left: 15px;
		margin-right: 15px;
	}
}

/*
 * cp-header global message box
 */
.cp-header .cp-global-msg{
	min-height: 65px;
	background: var(--success);
	text-align: center;
	margin-top: 43px;
}
.gifting-checkout-pg .cp-header .cp-global-msg{
	margin-top: 0;
}
.cp-header .cp-global-msg p{
	margin: 0px;
	padding: 0.8em;
}

/*
 * CP Footer
 */
.cp-footer-links {
	font-size: 1.15em;
}
.cp-footer-nav {
	padding-left: 15px;
}
.cp-footer-nav > ul > li {
	display: inline-block;
	font-size: 1em;
}
.cp-footer-btn {
	width: 100%;
}
.cp-footer-btn > a {
	border-bottom: 1px solid var(--neutral-medium);
	color: var(--neutral-black);
	display: block;
	padding: 10px;
	position: relative;
	text-decoration: none;
}
.cp-footer-btn .cp-icon {
	bottom: 6px;
	display: block;
	height: 25px;
	position: absolute;
	right: 15px;
	width: 20px;
}
.cp-footer-btn .cp-icon.arrow {
	background-position: -141px -124px;
}
.cp-footer-btn  .cp-icon.phone {
	background-position: -542px -127px;
}
.cp-footer-btn > a:hover {
	background: var(--auxiliary-blue-extra-light);
	background: -moz-linear-gradient(top, var(--auxiliary-blue-extra-light) 0%, var(--auxiliary-blue-light) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,var(--auxiliary-blue-extra-light)), color-stop(100%,var(--auxiliary-blue-light)));
	background: -webkit-linear-gradient(top, var(--auxiliary-blue-extra-light) 0%,var(--auxiliary-blue-light) 100%);
	background: -o-linear-gradient(top, var(--auxiliary-blue-extra-light) 0%,var(--auxiliary-blue-light) 100%);
	background: -ms-linear-gradient(top, var(--auxiliary-blue-extra-light) 0%,var(--auxiliary-blue-light) 100%);
	background: linear-gradient(to bottom, var(--auxiliary-blue-extra-light) 0%,var(--auxiliary-blue-light) 100%);
}
.copyright {
	padding: 15px;
	text-align: center;
	width: 100%;
	margin-bottom: 15px;
}
.copyright > .text-line {
	white-space: nowrap;
}
.copyright a {
	color: var(--neutral-cool-medium);
	text-decoration: none;
}
.copyright a:hover {
	text-decoration: underline;
}
.cp-footer-btn.fb-share {
	text-align: center;
}
@media (min-width: 568px) {
	.cp-footer-links {
		text-align: center;
	}
	.cp-footer-nav {
		padding-left: 0;
	}
	.cp-footer-btn {
		border-bottom: none;
		width: auto;
	}
	.cp-footer-btn > a {
		border: none;
		padding: 0 15px;
	}
	.cp-footer-btn > a:hover {
		background: transparent;
		text-decoration: underline;
	}
	.cp-footer-btn .cp-icon {
		display: none;
		visibility: hidden;
	}
	.cp-footer-btn + .cp-footer-btn {
		border-left: 1px solid var(--neutral-medium);
	}
	.cp-footer-btn.fb-share {
		border-left: 1px solid var(--neutral-medium);
		padding: 0 15px;
	}
}
@media (max-width: 849px) {
	.cp-footer-btn.contact {
		border-left: 0;
	}
	.cp-footer-btn.fb-share{
		border-left: none;
		margin-top: 20px;
	}
	.cp-footer-links {
		margin: 0 auto;
		max-width: 530px;
	}
}
@media (min-width: 960px) {
	.article-header{
		padding: 0px;
	}
}